import React from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Notifications, UserMenu, PortalSelector } from "insify-remote-component-loader";
import { AuthenticationProvider, UseAccountValue, useAccountWasm } from "./useAccountWasm";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { convertDotnetCallbackToFunction } from "./common";

const UserMenuWrapper = ({props}) => {
    props.links?.forEach(link => {
        link.onClick = convertDotnetCallbackToFunction(link.onClick);
    });

    const enhancedProps = {
        history: useHistory(),
        // Use account wasm tries to grab account information from the WASM Oicd library
        account: useAccountWasm(),
        ...props
    };

    return <UserMenu {...enhancedProps}></UserMenu>;
}

const PortalSelectorWrapper = ({ props }) => {
    const enhancedProps = {
        account: useAccountWasm(),
        ...props
    };
    return (
        <PortalSelector {...enhancedProps} />)
}

const BlazorRuntime = ({accountData, children}: {accountData: any, children?: any}) =>
    <BrowserRouter basename={""}>
        <ThemeProvider theme={theme}>
            <AuthenticationProvider accountData={accountData}>
                {children}
            </AuthenticationProvider>
        </ThemeProvider>
    </BrowserRouter>;

const NotificationsWrapper = ({props}) => {
    if (props.setIsOpen) {
        const { instance, methodName } = props.setIsOpen;
        props.setIsOpen = (isOpen) => {
            console.log("setting to open: ", isOpen);
            instance.invokeMethodAsync(methodName, isOpen);
        }
    }

    const enhancedProps = {
        history: useHistory(),
        account: useAccountWasm(),
        ...props
    };

    return <Notifications {...enhancedProps}/>;
}

(window as any).Etuity = {
    renderUserMenu: <TProps extends {}>(
        props: TProps,
        accountData: UseAccountValue,
        targetElement: HTMLElement
    ) => {
        ReactDOM.render(
            <BlazorRuntime accountData={accountData}>
                <UserMenuWrapper props={props} />
            </BlazorRuntime>,
            targetElement
        );
    },

    renderNotifications: (
        props: any,
        accountData: UseAccountValue,
        targetElement: HTMLElement
    ) => {
        ReactDOM.render(
            <BlazorRuntime accountData={accountData}>
                <NotificationsWrapper props={props} />
            </BlazorRuntime>,
            targetElement
        );
    },
    renderPortalSelector: <TProps extends {}>(
        props: TProps,
        accountData: UseAccountValue,
        targetElement: HTMLElement
    ) => {
        ReactDOM.render(
            <BlazorRuntime accountData={accountData}>
                <PortalSelectorWrapper props={props} />
            </BlazorRuntime>,
            targetElement
        );
    }
};