export interface IDotNetCallback {
    instance: any,
    methodName: string
};

export const convertDotnetCallbackToFunction = (callback : IDotNetCallback|Function|null) : Function => {
    if (callback === null || callback === undefined) {
        return () => {};
    }

    if (!(callback instanceof Function)) {
        const { instance, methodName } = callback;
        return () => instance.invokeMethodAsync(methodName);
    }

    return callback;
}