import React, { createContext, useContext } from "react";
import { convertDotnetCallbackToFunction } from "./common";

interface UserInfo {
    name?: string;
    email?: string;
};

export interface UseAccountValue {
    user: UserInfo | null;
    token: string | null;
    isAuthenticated: boolean;
    login: (options?: any) => void;
    logout: () => void;
    signinCallback: () => Promise<any>;
    isAuthenticationError?: boolean;
};

const AuthenticationContext = createContext<UseAccountValue | undefined>(undefined);

export const AuthenticationProvider =({ children, accountData }: { children?: React.ReactNode, accountData: any }) => {
    const mappedAccountData = {
        ...accountData,
        login: convertDotnetCallbackToFunction(accountData.login),
        logout: convertDotnetCallbackToFunction(accountData.logout),
        signinCallback: convertDotnetCallbackToFunction(accountData.signinCallback),
    };

    return (
        <AuthenticationContext.Provider
            value={ mappedAccountData }
        >
            {children}
        </AuthenticationContext.Provider>
    );
};

export const useAccountWasm = () => useContext<UseAccountValue>(AuthenticationContext as React.Context<UseAccountValue>);
